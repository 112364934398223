import React, { useEffect, useState, useCallback } from "react";
import { ethers } from "ethers";
import contract from "./ethereum/contract";
import BlockchainParams from "./Blockchain-Params";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  // Define the state variables
  const [walletAddress, setWalletAddress] = useState("");
  const [signer, setSigner] = useState();
  const [smartContract, setSmartContract] = useState();
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [stakeAmount, setStakeAmount] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [isInitializing, setIsInitializing] = useState(true); // Flag to track initialization
  const [stakeIndex, setStakeIndex] = useState(0);
  const [stakeWithdrawAmount, setStakeWithdrawAmount] = useState(0);
  const [tableContent, setTableContent] = useState([]);
  const [transactionData, setTransactionData] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);

  // Define the blockchain parameters
  const BLOCKCHAIN_PARAMS = BlockchainParams;

  // Initialize the application
  useEffect(() => {
    async function init() {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const accounts = await provider.send("eth_requestAccounts", []);
          setSigner(provider.getSigner());
          setSmartContract(contract(provider));
          setWalletAddress(accounts[0]);
          setIsConnected(true);
          await checkAndSwitchNetwork();
          setIsInitializing(false); // Mark initialization as complete
        } catch (err) {
          console.error(err.message);
          setIsInitializing(false); // Mark initialization as complete (even in case of error)
        }
      } else {
        console.log("Please install MetaMask");
        setIsInitializing(false); // Mark initialization as complete (even in case of error)
      }
    }

    init();
  }, []);

  // Fetch token balance when walletAddress, smartContract, or signer changes
  const getBalance = useCallback(async () => {
    try {
      if (smartContract && signer && walletAddress) {
        const smartContractWithSigner = smartContract.connect(signer);
        const balanceInWei = await smartContractWithSigner.balanceOf(
          walletAddress
        );
        const balanceInEther = ethers.utils.formatEther(balanceInWei);
        setTokenBalance(balanceInEther);
      } else {
        console.error("smartContract, signer, or walletAddress is undefined");
      }
    } catch (err) {
      console.error(err.message);
    }
  }, [smartContract, signer, walletAddress]);

  // Use useEffect to call getBalance when dependencies change
  useEffect(() => {
    // Only call getBalance when initialization is complete
    if (!isInitializing) {
      getBalance();
    }
  }, [getBalance, isInitializing]);

  // Add event listeners
  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      if (accounts.length > 0) {
        setWalletAddress(accounts[0]);
        setIsConnected(true);
      } else {
        setWalletAddress("");
        setIsConnected(false);
      }
    };

    const handleChainChanged = (_chainId) => {
      checkAndSwitchNetwork();
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      window.ethereum.on("chainChanged", handleChainChanged);

      // Cleanup function
      return () => {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      };
    }
  }, []);

  // for detecting if the user is on the correct network
  const checkAndSwitchNetwork = async () => {
    if (window.ethereum) {
      try {
        const currentChainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        if (currentChainId !== BLOCKCHAIN_PARAMS.chainId) {
          setIsWrongNetwork(true);
        } else {
          setIsWrongNetwork(false);
        }
      } catch (switchError) {
        console.error(switchError);
      }
    }
  };

  // Provide users the option to switch to the correct network if they are on a different network.
  const switchToCorrectChain = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [BLOCKCHAIN_PARAMS],
      });
      setIsWrongNetwork(false);
    } catch (switchError) {
      console.error(switchError);
    }
  };

  // Define the function to connect the wallet
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.send("eth_requestAccounts", []);
        setSigner(provider.getSigner());
        setSmartContract(contract(provider));
        setWalletAddress(accounts[0]);
        setIsConnected(true);
        await checkAndSwitchNetwork();
        getBalance(); // Now that smartContract and signer are initialized, call getBalance
      } catch (err) {
        console.error(err.message);
      }
    } else {
      console.log("Please install MetaMask");
    }
  };

  // Define the function to stake tokens
  const getStakeHandler = async () => {
    try {
      if (smartContract && signer && walletAddress) {
        const stakeAmountInWei = stakeAmount;

        // Encode the function call with the correct method signature and parameter
        const data = smartContract.interface.encodeFunctionData(
          "stake(uint256)",
          [stakeAmountInWei]
        );
        console.log("Encoded function data:", data);

        // Specify a gas limit here
        const gasLimit = await signer.estimateGas({
          to: smartContract.address,
          data: data,
        });

        const tx = await signer.sendTransaction({
          to: smartContract.address,
          data: data,
          gasLimit: gasLimit,
        });

        await tx.wait();
        console.log(`Transaction hash: ${tx.hash}`);

        // Construct the block explorer URL
        const blockExplorerUrl = `https://otter.pulsechain.com/tx/${tx.hash}`;

        // Set the transaction data in state
        setTransactionData(blockExplorerUrl);
        setTransactionHash(tx.hash);

        // Transaction successful, update the relevant state variables
        getBalance(); // Trigger a re-render of getBalance
        getStakes(); // Trigger a re-render of getStakes

        // Reset stakeAmount to zero after successful transaction
        setStakeAmount(0);
      } else {
        console.error("smartContract, signer, or walletAddress is undefined");
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  // Define the function to withdraw tokens
  const getStakeWithdrawHandler = async () => {
    try {
      if (smartContract && signer && walletAddress) {
        const stakeWithdrawAmountInWei = stakeWithdrawAmount;

        // Encode the function call with the correct method signature and parameter
        const data = smartContract.interface.encodeFunctionData(
          "withdrawStake(uint256, uint256)",
          [stakeWithdrawAmountInWei, stakeIndex]
        );
        console.log("Encoded function data:", data);

        // Specify a gas limit here
        const gasLimit = await signer.estimateGas({
          to: smartContract.address,
          data: data,
        });

        const tx = await signer.sendTransaction({
          to: smartContract.address,
          data: data,
          gasLimit: gasLimit,
        });

        await tx.wait();
        console.log(`Transaction hash: ${tx.hash}`);

        // Construct the block explorer URL
        const blockExplorerUrl = `https://otter.pulsechain.com/tx/${tx.hash}`;

        // Set the transaction data in state
        setTransactionData(blockExplorerUrl);
        setTransactionHash(tx.hash);

        // Transaction successful, update the relevant state variables
        getBalance(); // Trigger a re-render of getBalance
        getStakes(); // Trigger a re-render of getStakes

        // Reset stakeWithdrawAmount and stakeIndex to zero after successful transaction
        setStakeWithdrawAmount(0);
        setStakeIndex(0);
      } else {
        console.error("smartContract, signer, or walletAddress is undefined");
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  // Define the function to get stakes
  const getStakes = useCallback(async () => {
    try {
      if (smartContract && signer && walletAddress) {
        const smartContractWithSigner = smartContract.connect(signer);
        const stakedData = await smartContractWithSigner.hasStake(
          walletAddress
        );

        console.log("Staked Data:", stakedData);
        // setStakedBalance(stakedData[0] / 10 ** 18);
        setTableContent(stakedData[1]);
        console.log("tableContent:", stakedData[1]);
      } else {
        console.error("smartContract, signer, or walletAddress is undefined");
      }
    } catch (err) {
      console.error(err.message);
    }
  }, [smartContract, signer, walletAddress]);

  // Use useEffect to call getBalance when dependencies change
  useEffect(() => {
    // Only call getBalance when initialization is complete
    if (!isInitializing) {
      getStakes();
    }
  }, [getStakes, isInitializing]);

  // Define the function to show table data
  function showTableData() {
    if (tableContent.length === 0) {
      return <p>No stake data available.</p>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Stake Bag Index #️</th>
            <th>Available to withdraw</th>
            <th>Last Deposit/Withdraw time</th>
            <th>JPEG interest earned (upon withdrawal)</th>
          </tr>
        </thead>
        <tbody>
          {tableContent.map((row, i) =>
            Number(row[0]) === 0 || Number(row[2]) === 0 ? null : (
              <tr key={i}>
                <td>{i}</td>
                <td>{`${row[1] / 10 ** 18} JPEG`}</td>
                <td>{new Date(row[2] * 1000).toLocaleString()}</td>
                <td>{`${row[3] / 10 ** 19} JPEG`}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  }

  // Return the application
  return (
    <div>
      {/* Linear gradient background with spinning logo */}
      <div className="gradient-background">
        <Navigation // Pass in the required props
          isWrongNetwork={isWrongNetwork}
          isConnected={isConnected}
          walletAddress={walletAddress}
          switchToCorrectChain={switchToCorrectChain}
          connectWallet={connectWallet}
        />

        <section className="hero is-fullheight">
          <div className="dapp-hero-body">
            <div className="container has-text-centered main-content">
              <br />
              <br />
              <br />
              <iframe
                width="448"
                height="252"
                src="https://www.youtube.com/embed/6YgJxI3iFis??rel=0?si=l9ytzxMRX47ihmFy&amp;controls=0"
                title="YouTube video player"
                frameBorder="0"
                allow="autoplay; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p>
                Brought to you by the{" "}
                <a href="https://bigpp.team">bigpp.team</a>
              </p>
              <h1 className="title is-3">
                Earn 87.6% interest per year, staking JPEG!
              </h1>
              <br />
              <div>
                <h1 className="title is-3">token balance: {tokenBalance}</h1>
              </div>
              <div>Your connected wallet address is: {walletAddress}</div>
              <br />
              <br />
              <h1 className="title is-3">How it works</h1>
              <p>
                Each time you stake JPEG, you create a new stake bag. You can
                create as many JPEG stake bags as you like! You can also
                withdraw JPEG whenever you like, from any of your stake bags,
                but withdrawals from each subsequent stake bag incur a 1%
                incremental withdrawl fee.
              </p>
              <br />
              <br />
              <p>
                You can only ever deposit once per stake bag, which means you
                cannot add JPEG to an existing bag, however you can withdraw
                from each stake bag in full or in part.
              </p>
              <br />
              <br />
              <p>
                If you withdraw in full, then the stake bag is deleted and no
                longer earns interest, but if you withdraw part of your stake
                bag, then the part left inside the stake bag will continue to
                earn interest
              </p>

              <div className="box address-box">
                <div className="columns">
                  <div className="column is-four-fifths">
                    <h1 className="title is-3">Stake JPEG</h1>
                    <div className="input-container">
                      <br />
                      <br />

                      <span>
                        Whole number of JPEG Tokens you wish to stake:{" "}
                      </span>
                      <input
                        name="stakeAmount"
                        className="input is-medium"
                        placeholder="Number of Tokens: "
                        type="number"
                        min={0}
                        value={stakeAmount}
                        onChange={(e) => setStakeAmount(e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    className="button is-link is-medium"
                    onClick={getStakeHandler}
                    disabled={walletAddress ? false : true}
                    style={{ marginLeft: "0px", marginTop: "70px" }}
                  >
                    STAKE
                  </button>
                </div>

                <div className="box address-box">
                  <div className="columns">
                    <div className="column is-four-fifths">
                      <h1 className="title is-3">Withdraw JPEG</h1>
                      <div className="input-container">
                        <div style={{ paddingLeft: 100 }}>
                          <span>Stake Bag Index #️ </span>
                          <input
                            name="stakeIndex"
                            className="input is-medium"
                            placeholder="Stake index number: "
                            type="number"
                            min={0}
                            value={stakeIndex}
                            onChange={(e) => setStakeIndex(e.target.value)}
                            maxLength={20} // Limit the input to 20 characters
                          />
                        </div>
                        <div>
                          <span>Amount</span>

                          <input
                            name="stakeWithdrawAmount"
                            className="input is-medium"
                            placeholder="Stake withdrawal amount: "
                            type="number"
                            min={0}
                            value={stakeWithdrawAmount}
                            onChange={(e) =>
                              setStakeWithdrawAmount(e.target.value)
                            }
                            maxLength={20} // Limit the input to 20 characters
                          />
                        </div>
                        <button
                          className="button is-link is-medium"
                          onClick={getStakeWithdrawHandler}
                          disabled={walletAddress ? false : true}
                          style={{ marginLeft: "30px", marginTop: "23px" }}
                        >
                          Withdraw JPEG
                        </button>
                      </div>
                      <br />
                      <span>
                        Use whole numbers only, and do NOT include the interest
                        earned in the withdraw amount, it will be added
                        automatically!
                      </span>
                    </div>
                  </div>

                  <div className="flex center">
                    <h1 className="title is-3">Your Current Stake Bags</h1>
                    <div className="flex center">{showTableData()}</div>
                  </div>

                  <article className="panel is-grey-darker">
                    <p className="panel-heading">Transaction Data</p>
                    <div className="panel-block">
                      {transactionData ? (
                        <a
                          href={transactionData}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Transaction Data: {transactionHash}
                        </a>
                      ) : (
                        "--"
                      )}
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>

        <br />

        <div style={{ backgroundColor: "black", textAlign: "center" }}>
          <div className="panel-heading">
            <h1 className="title">
              Use GoPulse or PulseX to obtain JPEG tokens
            </h1>
            <p>
              Please ensure that you use only the official Pulsechain JPEG token
              address:
            </p>
            <p>
              <a href="https://otter.pulsechain.com/address/0xFEc51F5F06De3eC2F6c7d25bAAFC58D684578A8B">
                0xFEc51F5F06De3eC2F6c7d25bAAFC58D684578A8B
              </a>
            </p>
          </div>
        </div>

        <iframe
          title="JPEG"
          src="https://gopulse.com/x?out=0xFEc51F5F06De3eC2F6c7d25bAAFC58D684578A8B"
          height="660px"
          width="100%"
          id="myId"
        />

        <Footer />
      </div>
    </div>
  );
}

export default App;
