import React from "react";

function Footer() {
  return (
    <footer className="footer custom-footer">
      <div className="container">
        <div className="content has-text-centered">
          <p>
            Copyright © 2023 JPEG Token. brought to you by the{" "}
            <a href="https://bigpp.team">bigpp.team</a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
