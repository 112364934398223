const BlockchainParams = {
  chainId: "0x171", // 369 in hexadecimal
  chainName: "PulseChain",
  nativeCurrency: {
    name: "Pulse",
    symbol: "PLS",
    decimals: 18,
  },
  rpcUrls: ["https://rpc.pulsechain.com"],
  blockExplorerUrls: ["https://otter.pulsechain.com/"],
};

export default BlockchainParams;
