import React from "react";
import "../App.css";
import Logo from "../images/logo.png";

function Navigation({
  isWrongNetwork,
  isConnected,
  walletAddress,
  switchToCorrectChain,
  connectWallet,
}) {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          <img src={Logo} width={70} alt="logo" />
          <h1 className="navbar-item is-size-4">JPEG on Pulsechain</h1>
        </div>
        <div id="navbarMenu" className="navbar-menu">
          <div className="navbar-end is-align-items-center">
            {/* wrong network */}
            {isWrongNetwork && (
              <div className="network-warning">
                <p>
                  Wrong network - would you like to switch back to PulseChain?
                </p>
                <button onClick={switchToCorrectChain}>
                  Switch to PulseChain
                </button>
              </div>
            )}
            {/* not connected to metamask */}
            {!isConnected && (
              <button
                className="button is-white connect-wallet"
                onClick={connectWallet}
              >
                Connect Wallet
              </button>
            )}

            {/* connected to metamask */}
            {isConnected && (
              <button className="button is-white connect-wallet">
                <span className="is-link has-text-weight-bold">
                  Connected: {walletAddress.substring(0, 6)}...
                  {walletAddress.substring(38)}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
